const { Global, css } = require('@emotion/react');
const reset = require('emotion-reset');

const { StencilProvider } = require('@amzn/stencil-react-components/context');

const GlobalStyles = () => (
  <Global
    styles={css`
      ${reset.default}
      * {
        box-sizing: border-box;
      }

      html,
      body,
      #___gatsby {
        min-height: 100vh;
      }

      html {
        font-family: sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-text-size-adjust: 100%;
        text-rendering: optimizeLegibility;
      }
    `}
  />
);

const wrapRootElement = ({ element }) => {
  return (
    <StencilProvider>
      <GlobalStyles />
      {element}
    </StencilProvider>
  );
};

export { wrapRootElement };
